
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import AppService, {
  getConfigs,
  convertSlug,
  setConfigs,
} from "@/core/services/AppService";
export default defineComponent({
  name: "menu-component",

  setup() {
    const store = useStore();
    const key = "position_career";
    const loading = ref<boolean>(false);
    let objectData = ref({
      position_career: [],
    });
    const showEdit = ref<any[]>([]);
    const positionItems = ref<any[]>(objectData.value.position_career);
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Cấu hình Website", ["Cấu hình"]);
      MenuComponent.reinitialization();
      await getConfigs(key).then((data) => {
        if (typeof data === "object") {
          objectData.value = { ...objectData.value, ...data };
          positionItems.value = objectData.value.position_career;
          showEdit.value = Array.from(
            { length: objectData.value.position_career.length },
            (_, i) => false
          );
        }
      });
      // setConfigs({key:"home_config",value:objectData.value})
    });
    const addPositionItem = () => {
      positionItems.value.push({
        value: "Vị trí tuyển dụng",
        key: "vi-tri",
      });
    };

    const removePositionItem = (index) => {
      positionItems.value.splice(index, 1);
    };
    const formRef = ref<null | HTMLFormElement>(null);
    const submit = () => {
      setConfigs({ key, value: objectData.value })
        .then(() => {
          Swal.fire({
            text: "Cập nhật thông menu thành công",
            icon: "success",
            buttonsStyling: false,
            showConfirmButton: false,
            timer: 1000,
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Thử lại!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    };
    return {
      submit,
      loading,
      showEdit,
      objectData,
      convertSlug,
      addPositionItem,
      removePositionItem,
      positionItems,
    };
  },
});
